
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import Markdown from 'react-markdown'

/**
 * 展示消息列表
 * @param {Object[]} messages - 消息列表
 * @param {number} messages.type - 消息类型
 * @param {string} messages.content - 消息内容
 * @param {string} messages.talkerName - 发送者名称
 * @param {string} messages.receiveAt - 接收时间
 * @param {string} accountType - 当前用户账号类型
 * @param {string} accountID - 当前用户账号 ID
 */

export function MessageList({ messages, accountType, accountID }) {
    //渲染消息列表
    const viewContent = (message) => {
        switch (message.type) {
            case 7:
                return <Markdown>{message.content}</Markdown>
            case 1:
                return "[附件]"
            case 2:
                return "[语音]"
            case 3:
                return "[联系人]"
            case 4:
                return "[聊天历史]"
            case 5:
                return "[表情]"
            case 6:
                return "[图片]"
            case 8:
                return "[地图定位]"
            case 9:
                return "[小程序]"
            case 10:
                return "[群公告]"
            case 11:
                return "[转账]"
            case 12:
                return "[红包]"
            case 13:
                return "[撤回]"
            case 14:
                return "[链接]"
            case 15:
                return "[视频]"

            default:
                return "[未知信息]"
        }
    }

    //渲染每一行消息
    const viewLineRender = (line) => {
        //判断是否为当前用户发送的消息
        if (line.talkerName == accountID) {
            return <div className="line revert" key={`${line.accountType}-${line.accountID}-${line.id}`}>
                <div className="sender"><LeftOutlined size="12px" style={{ fontSize: 12 }} /> {line.talkerName} </div>
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                    <div className="time">{dayjs(line.receiveAt).format("MM-DD HH:mm")}</div>
                    <div className="content">{viewContent(line)}</div>
                </div>
            </div>
        } else {
            return <div className="line" key={`${line.accountType}-${line.accountID}-${line.id}`}>
                <div className="sender">{line.talkerName} <RightOutlined size="12px" style={{ fontSize: 12 }} /></div>
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                    <div className="content">{viewContent(line)}</div>
                    <div className="time">{dayjs(line.receiveAt).format("MM-DD HH:mm")}</div>
                </div>
            </div>
        }
    }

    return messages?.map(viewLineRender)
}