import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom'
import './App.css';
import { ConfigProvider } from "antd"
import zhCN from 'antd/lib/locale/zh_CN';
import 'dayjs/locale/zh-cn';

import PageSignIn from "./PageSignIn"
import Layout from "./_Layout"
import { Group } from './Group';
import { Messages } from './messages';
import { Setting } from './Setting';
import { Friends } from './Friends';

function App() {
  return <ConfigProvider locale={zhCN}>
    <HashRouter>
      <Routes>
        <Route path="/sign_in" exact element={<PageSignIn />} ></Route>
        <Route path="/" element={<Layout />}>
          <Route path='/group' element={<Group />}></Route>
          <Route path='/friend' element={<Friends />}></Route>
          <Route path='/message/:owner_type/:owner_id' element={<Messages />}></Route>
          <Route path='/setting' element={<Setting />}></Route>
        </Route>
        <Route path="*" element={<PageSignIn />} />
      </Routes>
    </HashRouter>
  </ConfigProvider>;
}

export default App;
