
import { useEffect, useRef, useState } from "react"
import { selectJsonRequest, selectSocket, selectAccount, selectOnline, NavActive } from "../components/Global"
import { useSelector } from "react-redux"
import { Input, Pagination, message } from "antd"
import { useDispatch } from "react-redux"
import { MessageList } from "./components/MessageList"

let _page = 0;

export function Paginated({ ownerType, ownerID }) {
    const jsonRequest = useSelector(selectJsonRequest)
    const socket = useSelector(selectSocket)
    const [messages, setMessages] = useState({})
    const [size, setSize] = useState(10)
    const account = useSelector(selectAccount)
    const isOnline = useSelector(selectOnline)
    // 输入的消息
    const [messageSendText, setMesasgeSendText] = useState("")
    const dispatch = useDispatch();

    // 消息的最下面
    const messageBottomRef = useRef()

    useEffect(() => {
        socket
            .on("no-contact", () => {
                message.warning('消息发送失败，找不到联系人')
            })
            .on("no-room", () => {
                message.warning('消息发送失败，找不到群')
            })
            .on("message", () => {
                load(_page, size)
            })

        dispatch(NavActive(ownerID));
        load(null, 10)

        return () => {
            socket.off("message")
            socket.off("no-contact")
            socket.off("no-room")
        }
    }, [ownerType, ownerID, account])


    const load = (page, size) => {
        _page = page
        setSize(size)
        jsonRequest.get("/bot/terminal/messages", { page, size, owner_type: ownerType, owner_id: ownerID })
            .then(resp => resp.json())
            .then(({ data }) => {
                setMessages(data)
                _page = page
                setTimeout(() => {
                    scrollToBottom()
                }, 100);
            })
    }

    // 发送消息
    const handleMessageSend = () => {
        socket.emit("message-send", { text: messageSendText, ownerType, ownerID })
        setMesasgeSendText("")
    }

    const scrollToBottom = () => {
        messageBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    return <>
        <div id="messages" className="messages">
            <MessageList messages={messages.data} accountType={account?.type} accountID={account?.id} />

            <div ref={messageBottomRef} />
        </div>

        <div style={{ margin: "5px 0", display: "flex", alignItems: "center", paddingRight: 10, columnGap: 10 }}>
            <Pagination total={messages.total} defaultCurrent={1} current={messages.page} pageSize={messages.size} size="small" onChange={load} />
            {isOnline &&
                <Input
                    value={messageSendText}
                    onChange={(e) => setMesasgeSendText(e.target.value)}
                    size="small"
                    style={{ flex: "1 1 300px" }}
                    onKeyDown={e => e.key === "Enter" && handleMessageSend()} />
            }
        </div>
    </>
}