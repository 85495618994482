import config from '../config'
import { NavActive, selectJsonRequest, selectAccount, selectAccounts } from "./Global"

class JsonRequest {
    // 用用户和当前账户信息创建一个带有 token 的 JsonRequest 实例
    static tokenClient(user, currentAccount) {
        return new JsonRequest({ Authorization: `Bearer ${user?.token}` }, currentAccount,user)
    }

    headers = { 'Content-Type': 'application/json' }
    account = {}

    setAccount(account) {
        this.account = account
    }

    // 设置用户信息，包括 token
    setUser(user) {
        this.headers = { ...this.headers, Authorization: `Bearer ${user?.token}` }
    }

    constructor(headers = {}, account, user) {
        // 合并请求头信息
        this.headers = { ...headers, ...this.headers }
        this.account = account
        this.user = user
    }

    // 发起请求的方法
    request(path, method, values, headers = {}) {
        // 设置请求选项
        const requestOptions = { method: method, headers: { ...headers, ...this.headers } }

        if (method !== "FILE") {
            values = values || {}
        }

        switch (method) {
            case "GET":
            case "DELETE":
                if (values) {
                    for (var key in values) {
                        if (values[key] == null || typeof (values[key]) == "undefined") {
                            delete values[key];
                        }
                    }
                }
                path = `${path}?${new URLSearchParams(values).toString()}`
                break;
            case "POST":
            case "PATCH":
            case "PUT":
                // 将请求体转换为 JSON 字符串
                requestOptions["body"] = JSON.stringify(values);
                break;
            case "FILE":
                // 添加账户类型和账户 ID 到表单数据中
                values.append("accountType", this.account?.type)
                values.append("accountID", this.account?.id)
                requestOptions["body"] = values;
                requestOptions["method"] = "POST"
                delete requestOptions["headers"]["Content-Type"]
                break;
            default:
                break;
        }
        return fetch(config.HttpEndpoint(path), requestOptions)
    }

    post(path, obj, headers = {}) {
        return this.request(path, "POST", obj, headers)
    }

    get(path, obj, headers = {}) {
        return this.request(path, "GET", obj, headers)
    }

    put(path, obj, headers = {}) {
        return this.request(path, "PUT", obj, headers)
    }

    delete(path, obj, headers = {}) {
        return this.request(path, "DELETE", obj, headers)
    }

    upload(path, file, obj, headers = {}) {
        var data = new FormData()
        data.append('cover', file)
        for (const key in obj) {
            data.append(key, obj[key])
        }

        return this.request(path, "FILE", data, headers)
    }

    file(path, formData, headers = {}) {
        return this.request(path, "FILE", formData, headers)
    }
}

export default JsonRequest
