import { Divider, Form, Input, InputNumber, Select, Slider, Switch } from "antd"
import { useEffect, useState } from "react"

export function ProfileRequestForm({ form, initialValues, knowledgeName, knowledgeBaseData = [], style }) {
    const [xmindAIV2Enable, setXmindAIV2Enable] = useState(false)
    const [humanLikeEnable, setHumanLikeEnable] = useState(false)
    const [sessionParamEnable, setSessionParamEnable] = useState(false)
    const [bingDalle3Enable, setBingDalle3Enable] = useState(false)
    //知识库
    const [knowledgeBaseEnable, setKnowledgeBaseEnable] = useState(false)

    useEffect(() => {
        const xmindai_v2_enable = initialValues?.xmindai_v2?.enable || false
        const human_like_param_enable = initialValues?.human_like_param?.enable || false
        const session_param_enable = initialValues?.session_param?.enable || false
        const bing_dalle_3_enable = initialValues?.bing_dalle_3?.enable || false
        let knowledge_base_enable = false;
        setXmindAIV2Enable(xmindai_v2_enable)
        setHumanLikeEnable(human_like_param_enable)
        setSessionParamEnable(session_param_enable)
        setBingDalle3Enable(bing_dalle_3_enable)

        form?.setFieldsValue(initialValues)
        if (knowledgeName) {
            if (knowledgeName.enable === 1) knowledge_base_enable = true
            knowledgeBaseData.forEach(item => {
                if (item.name === knowledgeName.knowledgeName) {
                    form?.setFieldValue(["knowledge_base", "Name"], item.id)
                }
            });
        }
        setKnowledgeBaseEnable(knowledge_base_enable)

        form?.setFieldValue(["xmindai_v2", "enable"], xmindai_v2_enable)
        form?.setFieldValue(["human_like_param", "enable"], human_like_param_enable)
        form?.setFieldValue(["session_param", "enable"], session_param_enable)
        form?.setFieldValue(["bing_dalle_3", "enable"], bing_dalle_3_enable)
        form?.setFieldValue(["knowledge_base", "enable"], knowledge_base_enable)
    }, [initialValues, knowledgeName])


    return <Form form={form} size="small" autoComplete="off" initialValues={initialValues} style={{ display: "flex", ...style }} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
        <div style={{ width: "50%" }}>
            <Divider>Xmind AI v2 参数设置</Divider>
            <Form.Item name={["xmindai_v2", "enable"]} label="设置" valuePropName="checked" initialValue={xmindAIV2Enable} required>
                <Switch checkedChildren="启用" unCheckedChildren="不启用" checked={xmindAIV2Enable} onChange={setXmindAIV2Enable} />
            </Form.Item>
            <Form.Item name={["xmindai_v2", "model"]} label="AI模型" initialValue={'4'}>
                <Select disabled={!xmindAIV2Enable}>
                    <Select.Option value={'0'}>GPT35</Select.Option>
                    <Select.Option value={'1'}>GPT4</Select.Option>
                    <Select.Option value={'2'}>GPT_32K</Select.Option>
                    <Select.Option value={'3'}>GPT35_TURBO_16K</Select.Option>
                    <Select.Option value={'4'}>GPT35_TURBO_1106</Select.Option>
                    <Select.Option value={'5'}>GPT4_TURBO</Select.Option>
                    <Select.Option value={'6'}>GPT4_TURBO_VISION</Select.Option>
                    <Select.Option value={'7'}>GPT35_TURBO_0125</Select.Option>
                    <Select.Option value={'8'}>GPT4O</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name={["xmindai_v2", "temperature"]} label="temperature" initialValue={1} help="要使用的采样温度，介于 0 和 2 之间。 较高的值（如 0.8）将使输出更随机，而较小的值（如 0.2）将使输出更集中，更具确定性。">
                <Slider disabled={!xmindAIV2Enable} max={2} min={0} step={0.1}></Slider>
            </Form.Item>
            <Form.Item name={["xmindai_v2", "top_p"]} label="top_p" initialValue={1} help="温度采样的替代方法，称为核采样，其中模型考虑具有 top_p 概率质量的令牌的结果。 所以 0.1 意味着只考虑包含前 10% 概率质量的令牌。 ">
                <Slider disabled={!xmindAIV2Enable} max={2} min={0} step={0.1}></Slider>
            </Form.Item>
            <Form.Item name={["xmindai_v2", "presence_penalty"]} label="presence_penalty" initialValue={0} help="介于 -2.0 和 2.0 之间的数字。 正值会根据它们到目前为止在文本中的现有频率来惩罚新令牌，从而降低模型逐字重复同一行的可能性。">
                <Slider disabled={!xmindAIV2Enable} max={2} min={-2} step={0.1}></Slider>
            </Form.Item>
            <Form.Item name={["xmindai_v2", "frequency_penalty"]} label="frequency_penalty" initialValue={0} help="介于 -2.0 和 2.0 之间的数字。 正值会根据它们到目前为止在文本中的现有频率来惩罚新令牌，从而降低模型逐字重复同一行的可能性。">
                <Slider disabled={!xmindAIV2Enable} max={2} min={-2} step={0.1}></Slider>
            </Form.Item>
            <Form.Item name={["xmindai_v2", "stream"]} label="stream" valuePropName="checked" initialValue={true} required>
                <Switch disabled={!xmindAIV2Enable} checkedChildren="流式响应" unCheckedChildren="块式响应" />
            </Form.Item>
            <Form.Item name={["xmindai_v2", "max_tokens"]} label="max_tokens" initialValue={256} required>
                <InputNumber disabled={!xmindAIV2Enable} min={48} />
            </Form.Item>

            <Divider>知识库设置</Divider>
            <Form.Item name={["knowledge_base", "enable"]} label="设置" valuePropName="checked" initialValue={knowledgeBaseEnable} required>
                <Switch checkedChildren="启用" unCheckedChildren="不启用" checked={knowledgeBaseEnable} onChange={setKnowledgeBaseEnable} />
            </Form.Item>
            <Form.Item name={["knowledge_base", "Name"]} label="知识库名称" id="knowledgeBaseName" >
                <Select
                    disabled={!knowledgeBaseEnable}
                    allowClear
                    placeholder="请选择知识库"
                    options={knowledgeBaseData.map(item => ({
                        key: item.id,
                        value: item.id,
                        label: item.name,
                    }))}
                />
            </Form.Item>
        </div>

        <div style={{ width: "50%" }}>
            <Divider>Bing Dalle-3 设置</Divider>
            <Form.Item name={["bing_dalle_3", "enable"]} label="设置" valuePropName="checked" initialValue={bingDalle3Enable} required>
                <Switch checkedChildren="启用" unCheckedChildren="不启用" checked={bingDalle3Enable} onChange={setBingDalle3Enable} />
            </Form.Item>
            <Form.Item name={["bing_dalle_3", "size"]} label="分辨率" initialValue="1024x1024">
                <Select disabled={!bingDalle3Enable}>
                    <Select.Option value="1024x1024">宽1024x高1024</Select.Option>
                    <Select.Option value="1024x1792">宽1024x高1792</Select.Option>
                    <Select.Option value="1792x1024">宽1792x高1024</Select.Option>=
                </Select>
            </Form.Item>
            <Form.Item name={["bing_dalle_3", "style"]} label="风格" initialValue="vivid">
                <Select disabled={!bingDalle3Enable}>
                    <Select.Option value="natural">拟真</Select.Option>
                    <Select.Option value="vivid">漫画</Select.Option>
                </Select>
            </Form.Item>

            <Divider>模拟人输入延迟设置</Divider>
            <Form.Item name={["human_like_param", "enable"]} label="设置" valuePropName="checked" initialValue={humanLikeEnable} required>
                <Switch checkedChildren="启用" unCheckedChildren="不启用" checked={xmindAIV2Enable} onChange={setHumanLikeEnable} />
            </Form.Item>
            <Form.Item name={["human_like_param", "splitChar"]} label="切割字符" help="用于切割GPT文字成为段落，默认：\n\n" initialValue="\n\n">
                <Input disabled={!humanLikeEnable} placeholder="\n\n" />
            </Form.Item>
            <Form.Item name={["human_like_param", "splitPer"]} label="段落数量" help="输出时（如微信）每一条信息包含多少段，默认：1" initialValue={1}>
                <InputNumber disabled={!humanLikeEnable} min={1} addonAfter="段" />
            </Form.Item>
            <Form.Item name={["human_like_param", "wordsPerSeconds"]} label="每段延迟" help="用于控制每段话输出的延迟，默认：10，每秒打10个字。 正常人每秒最多5个字。" initialValue={10}>
                <InputNumber disabled={!humanLikeEnable} min={3} addonAfter="字 / 秒" />
            </Form.Item>

            <Divider>微信历史会话设置</Divider>
            <Form.Item name={["session_param", "enable"]} label="设置" valuePropName="checked" initialValue={sessionParamEnable} required>
                <Switch checkedChildren="启用" unCheckedChildren="不启用" checked={sessionParamEnable} onChange={setSessionParamEnable} />
            </Form.Item>
            <Form.Item name={["session_param", "historyLimit"]} label="历史会话条数" help="0条表示不查找历史会话" initialValue={30}>
                <InputNumber disabled={!sessionParamEnable} min={0} addonAfter="条" />
            </Form.Item>
            <Form.Item name={["session_param", "latestMinutes"]} label="最近时间" help="往前查多少分钟内的消息" initialValue={10}>
                <InputNumber disabled={!sessionParamEnable} min={0} addonAfter="分钟" />
            </Form.Item>
        </div>
    </Form>
}