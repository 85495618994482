
import { useEffect, useState } from "react"
import { NavActive, selectJsonRequest, selectAccount, selectUser } from "./components/Global"
import { useDispatch, useSelector } from "react-redux"
import { Button, message, Form, Upload, Popover, Drawer, Alert, Divider } from "antd"
import { DeleteOutlined, FileWordTwoTone, NumberOutlined, SaveFilled, UploadOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import { encodingForModel } from "js-tiktoken";

import { ProfileRequestForm } from "./components/ProfileRequestForm"

// gpt token计算
const enc = encodingForModel("gpt-3.5-turbo-1106");
export function Group() {
    const jsonRequest = useSelector(selectJsonRequest)
    const currentAccount = useSelector(selectAccount)
    const user = useSelector(selectUser)
    const [groups, setGroups] = useState([])
    const [prompts, setPrompts] = useState([])
    // 知识库的id
    const [knowledge, setKnowledge] = useState(null)
    // 当前激活的群主题
    const [activeRoom, setActiveRoom] = useState(null)
    // 第三方请求的配置文件
    const [profileRequest, setProfileRequest] = useState(null)
    // 正在预览的提示
    const [previewPrompt, setPreviewPrompt] = useState(null)
    const dispatch = useDispatch();

    const [form] = Form.useForm()
    const [knowledgeBaseData, setKnowledgeBaseData] = useState([])

    //获得知识库（知识库下拉框）
    const knowledgeBase = async () => {
        jsonRequest.get("/knowledgeBase/getAllName",{accountType: currentAccount.type, accountId: currentAccount.id, id: user.id})
            .then(response => response.json())
            .then(({ data }) => {
                setKnowledgeBaseData(data)
            })
            .catch(() => { })
    }

    //加载群
    useEffect(() => {
        dispatch(NavActive("group"));
        jsonRequest.get("/bot/terminal/groups", { accountType: currentAccount.type,accountId: currentAccount.id, username: user.username, id: user.id })
            .then(resp => resp.json())
            .then(({ data }) => {
                knowledgeBase()
                setGroups(data)
                handleClickGroup(data[0])
            })
        return () => { }
    }, [currentAccount])

    const handleClickGroup = (room) => {
        if (!room) return
        setActiveRoom(room)

        // 加载配置
        profileRequestLoad(room.topic)
        baseRequestLoad(room.roomId)
        jsonRequest.get("/bot/terminal/prompts", {accountType: currentAccount.type, accountId: currentAccount.id, id: user.id, owner_type: "bot_room", owner_id: room.id })
            .then(resp => resp.json())
            .then(({ data }) => {
                setPrompts(data)
            })
    }

    // 上传文件
    const handleUploadFile = (obj) => {
        const file = obj.file
        var data = new FormData()
        data.append('file', file)
        data.append('filename', file.name)
        data.append('ownerType', "bot_room")
        data.append('ownerID', activeRoom?.id)
        if (file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && file.type !== "application/msword") {
            return message.error("只能上传.doc和.docx文件");
        }

        message.loading({ content: "上传中，请稍后...", key: "handleUploadFile", duration: 0 })
        jsonRequest.file(`/bot/terminal/prompts/upload`, data)
            .then(resp => resp.json())
            .then(({ data }) => {
                message.success({ content: "上传成功", key: "handleUploadFile", duration: 2 })
                setTimeout(() => handleClickGroup(activeRoom), 500)
            })
    }

    const handleDeletePromptFile = (id) => {
        message.loading({ content: "删除中...", key: "handleDelete", duration: 0 })
        jsonRequest.delete(`/bot/terminal/prompts/${id}`)
            .then(resp => resp.json())
            .then(({ data }) => {
                message.success({ content: "删除成功", key: "handleDelete", duration: 2 })
                setTimeout(() => handleClickGroup(activeRoom), 500)
            })
    }

    const baseRequestLoad = (roomId, page = 1, size = 20) => {
        jsonRequest.get("/baseMessage/room-base/list", { page, size, roomName: roomId,accountType: currentAccount.type, accountId: currentAccount.id, id: user.id })
            .then(response => response.json())
            .then(({ data }) => {
                    setKnowledge(data.result[0] || {});
            })
            .catch(() => { })
    }

    const profileRequestLoad = (roomTopic) => {
        jsonRequest.get("/bot/terminal/setting", { ownerType: "bot_room", ownerID: roomTopic,accountType: currentAccount.type, accountId: currentAccount.id, id: user.id })
            .then(resp => resp.json())
            .then(({ data }) => {
                setProfileRequest(data)
            })
    }

    const profileRequestSave = () => {
        message.loading({ content: "提交中...", key: "profileRequestSave", duration: 0 })
        form
            .validateFields()
            .then((v) => {
                if (knowledge.enable) {
                    jsonRequest.post("/baseMessage/updateById", { accountType: currentAccount.type, accountId: currentAccount.id, id: knowledge.id, baseID: v.knowledge_base.Name, enable: v.knowledge_base.enable })
                        .then(resp => resp.json())
                        .then(() => { })
                        .catch(() => {
                            message.error('配置失败')
                            return;
                        })
                } else {
                    // 需要做一个类型判断
                    const baseID = typeof v.knowledge_base.Name === 'number' ? v.knowledge_base.Name : parseInt(v.knowledge_base.Name)
                    const ownerID = parseInt(activeRoom?.id)
                    jsonRequest.post("/vector/setting", { accountID: currentAccount.id,baseID, enable: true, ownerType: 'bot_room', ownerID })
                        .then(response => response.json())
                        .then(() => { })
                        .catch(() => {
                            message.error('配置失败')
                            return;
                        })
                }
                
                delete v.knowledge_base;
                jsonRequest.post("/bot/terminal/setting", { accountType: currentAccount.type, accountId: currentAccount.id, id: user.id,ownerType: "bot_room", ownerID: activeRoom?.topic, param: v })
                    .then(resp => resp.json())
                    .then(({ data }) => {
                        message.success({ content: "设置成功", key: "profileRequestSave", duration: 2 })
                        setTimeout(() => {
                            profileRequestLoad(activeRoom?.topic) 
                            baseRequestLoad(activeRoom?.roomId)
                        }, 1000)
                    })
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    // 预览提示
    const handeClickPromptPreview = (prompt) => {
        setPreviewPrompt(prompt)
    }

    // 修改提示
    const handleSavePromptModification = () => {
        message.loading({ content: "提交中...", key: "handleSavePromptModification", duration: 0 })
        jsonRequest.put(`/bot/terminal/prompts/${previewPrompt.id}`, {
            messages: document.querySelector("#prompt-review-editor").innerHTML,
            accountType: currentAccount.type, accountId: currentAccount.id, id: user.id
        }).then(resp => resp.json())
            .then(({ data }) => {
                message.success({ content: "设置成功", key: "handleSavePromptModification", duration: 2 })
                setTimeout(() => handleClickGroup(activeRoom), 500)
            })
    }


    return <div className="page group">
        <div className="content" style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <h2 style={{ fontWeight: 400, margin: "20px 0 10px 0" }}>群管理 <span style={{ color: "#888", fontSize: 16 }}>Group</span></h2>
            <div className="group list">
                {groups.map(group => {
                    return <Button key={group.id} className={{ active: activeRoom?.id === group.id }} onClick={() => handleClickGroup(group)}>{group.roomId}</Button>
                })}
                {groups?.length === 0 && <Alert message="没有可用的群" type="warning" showIcon />}
            </div>

            <div className="prompt-files">
                <Divider style={{ margin: "5px 0", color: "#aaa" }}>提示管理</Divider>
                {
                    prompts.map(prompt => {
                        return <div key={prompt.id} className="prompt">
                            <span style={{ flex: "1 1 auto" }} onClick={() => handeClickPromptPreview(prompt)}><FileWordTwoTone /> {prompt.name || "[无文件名]"} <span style={{ color: "#aaa", fontStyle: "italic", fontSize: 13 }}><NumberOutlined /> token: {enc.encode(prompt.messages).length / 1000}k</span></span>
                            <span>
                                <span style={{ color: "#888", fontSize: "14px" }}>{dayjs(prompt.updatedAt).format("YYYY-MM-DD HH:mm")}</span>
                                <Popover content={<Button danger type="link" onClick={() => handleDeletePromptFile(prompt.id)}>确定</Button>}>
                                    <Button size="small" icon={<DeleteOutlined />} danger type="link" onClick={e => e.preventDefault()}></Button>
                                </Popover>
                            </span>
                        </div>
                    })
                }

                <div style={{ width: "100%" }}>
                    <Upload.Dragger showUploadList={false} customRequest={(obj) => handleUploadFile(obj)} disabled={!activeRoom}>
                        <p className="ant-upload-text"><UploadOutlined /> 点击或拖拽上传提示文件（.doc, .docx）</p>
                    </Upload.Dragger>
                </div>
            </div>
            {groups?.length > 0 && activeRoom?.profile?.terminal?.profile_setting_allow && <Divider style={{ margin: "5px 0", color: "#aaa" }}>GPT参数设置</Divider>}
            {groups?.length > 0 && activeRoom?.profile?.terminal?.profile_setting_allow &&
                <div className="profile-request" style={{ flex: "1 1 auto", overflowY: "auto", minHeight: 0 }}>
                    {profileRequest && knowledge && <ProfileRequestForm form={form} initialValues={profileRequest} knowledgeName={knowledge} knowledgeBaseData={knowledgeBaseData}/>}
                </div>
            }
            {groups?.length > 0 && activeRoom?.profile?.terminal?.profile_setting_allow &&
                <Button block type="link" disabled={!activeRoom} icon={<SaveFilled />} onClick={profileRequestSave}>保存</Button>}

            <Drawer title={<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span>{previewPrompt?.name} <span style={{ color: "#aaa", fontStyle: "italic" }}><NumberOutlined /> token: {enc.encode(previewPrompt?.messages || "").length / 1000}k</span></span>
                <span><Button onClick={handleSavePromptModification} type="link">保存</Button></span></div>}
                placement="right"
                closable open={!!previewPrompt}
                onClose={() => setPreviewPrompt(null)} size="large">
                <pre id="prompt-review-editor" contentEditable style={{ outline: "0px solid transparent", whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                    {previewPrompt?.messages}
                </pre>
            </Drawer>
        </div>
    </div >
}