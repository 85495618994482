
import { useEffect, useState } from "react"
import { NavActive, selectOnline } from "../components/Global"
import { useDispatch } from "react-redux"
import { Badge, Segmented } from "antd"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { Paginated } from "./Paginated"
import { Chat } from "./Chat"

export function Messages() {

    let { owner_type, owner_id } = useParams()
    const isOnline = useSelector(selectOnline)
    const dispatch = useDispatch();
    const [mode, SetMode] = useState("聊天窗口")

    useEffect(() => {
        dispatch(NavActive(owner_id));
    }, [])

    const messageView = (mode) => {
        switch (mode) {
            case "分页":
                return <Paginated ownerType={owner_type} ownerID={owner_id}></Paginated>
            case "聊天窗口":
                return <Chat ownerType={owner_type} ownerID={owner_id}></Chat>
                break;


            default:
                break;
        }
    }

    return <div className="page messages">
        <div className="content" style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-between", padding: "0 10px" }}>
                <h2 style={{ fontWeight: 400, margin: "20px 0 10px 0" }}>{owner_id} 
                <span style={{ color: "#888", fontSize: 16 }}>Messages</span> 
                <Badge color={isOnline ? "green" : "red"}></Badge>
                </h2>
                <span><Segmented onChange={(mode) => SetMode(mode)} value={mode} options={["分页", "聊天窗口"]}></Segmented></span>
            </div>

            {messageView(mode)}

        </div>
    </div>
}