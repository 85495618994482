
import { useEffect, useState } from "react"
import { NavActive, selectJsonRequest, selectAccount, selectUser } from "./components/Global"
import { useDispatch, useSelector } from "react-redux"
import { Table, Switch } from "antd"

export function Friends() {
    const jsonRequest = useSelector(selectJsonRequest)
    const currentAccount = useSelector(selectAccount)
    const user = useSelector(selectUser)
    const [friends, setFriends] = useState({})
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(NavActive("friend"));
        load(1, 20)
        return () => { }
    }, [currentAccount, user])

    const load = (page, size) => {
        jsonRequest.get("/bot/terminal/friends/pagination", { page, size, accountType: currentAccount.type, accountId: currentAccount.id, username: user.username, id: user.id })
            .then((resp) => resp.json())
            .then(({ data }) => {
                setFriends(data);
            });
    };    

    const handleSwitchGPTEnable = (enable, contactID) => {
        jsonRequest.post("/bot/terminal/setting/gpt/enable", { accountType: currentAccount.type, accountId: currentAccount.id, id: user.id, value: enable, ownerType: "bot_contact", ownerID: contactID })
            .then(resp => resp.json())
            .then(({ data }) => {
                load(1, 20)
            })
    }

    return <div className="page friends">
        <div className="content" style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <h2 style={{ fontWeight: 400, margin: "20px 0 10px 0" }}>好友管理 <span style={{ color: "#888", fontSize: 16 }}>Friends</span></h2>
        </div>
        <Table dataSource={friends?.data} rowKey={item => item.id} size="middle"
            pagination={{
                onChange: (page, size) => { load(page, size) },
                total: friends?.total,
                current: friends?.page,
                pageSize: friends?.size,
                showQuickJumper: true
            }}>
            <Table.Column title="昵称" align="left" dataIndex="contactId" key="contactId" />
            <Table.Column title="签名" align="left" dataIndex="signature" key="signature" />
            <Table.Column title="开启GPT" align="center" dataIndex={["profile", "gpt", "enable"]} key="profile" render={(v, i) => <Switch checked={v} checkedChildren="开启" unCheckedChildren={"关闭"} onChange={(checked) => handleSwitchGPTEnable(checked, i.contactId)} />} />
            {/* <Table.Column title="参数" align="center" dataIndex={["profile", "gpt", "enable"]} key="profile" render={(v, i) => <Button type="link" icon={<SettingTwoTone />}>设置</Button>} /> */}
        </Table>
    </div>
}