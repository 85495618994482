import { Button, Menu, Select } from "antd";
import { TeamOutlined, UserOutlined, SettingOutlined, WechatOutlined, GroupOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectNavActiveKey, selectSocket, selectAccount, selectAccounts, selectJsonRequest, signOut, assignAccount, selectUser } from "./Global";
import { useEffect, useState } from "react";

export function Nav(params) {
    const activeKey = useSelector(selectNavActiveKey)
    const socket = useSelector(selectSocket)
    const [contactList, setContactList] = useState([])
    const [roomList, setRoomList] = useState([])
    const account = useSelector(selectAccount)
    const accounts = useSelector(selectAccounts)
    const jsonRequest = useSelector(selectJsonRequest)
    const dispatch = useDispatch()
    const user = useSelector(selectUser)

    //在 account 变化时，通过WebSocket和请求获取好友列表和群列表的数据，并用回调函数更新组件的状态
    useEffect(() => {
        if (!!account) {
            socket.emit("change-account", { accountType: account.type, accountID: account.id, username: account.nickname })
            jsonRequest.get("/bot/terminal/friends/gpt", { accountType: account.type, accountId: account.id, id: user.id})
                .then(resp => resp.json())
                .then(({ data }) => {
                    let data1 = [];
                    data.forEach(item => {
                        if (item?.profile?.terminal?.enable === true) {
                            data1.push(item);
                        }
                    })
                    setContactList(data1)
                })
            jsonRequest.get("/bot/terminal/groups", { accountType: account.type, accountId: account.id, id: user.id })
                .then(resp => resp.json())
                .then(({ data }) => {
                    setRoomList(data)
                })
            socket.on("contact-list", ({ data }) => {
                let data1 = [];
                data.forEach(item => {
                    if (item?.profile?.terminal?.enable === true) {
                        data1.push(item);
                    }
                })
                setContactList(data1)
            })

            socket.on("room-list", ({ data }) => {
                setRoomList(data)
            })
        }

    }, [account])

    //退出登录
    const handleLogout = () => {
        // setLocalStorageUser(null)
        dispatch(signOut())
    }

    //账号切换
    const handleChangeAccount = (nickname) => {
        let account = accounts?.find(({ nickname: _nickname }) => _nickname === nickname)
        dispatch(assignAccount(account))
    }

    let contactListMenuItems = contactList.map((contact) => {
        return {
            label: <Link to={`/message/bot_contact/${contact.nickname}`}>{contact.nickname}</Link>,
            key: contact.nickname,
            icon: <UserOutlined />
        }
    })
    let roomListMenuItems = roomList.map((room) => {
        return {
            label: <Link to={`/message/bot_room/${room.topic}`}>{room.topic}（{room.raw.memberIdList.length}人）</Link>,
            key: room.topic,
            icon: <TeamOutlined />
        }
    })

    return <div className="nav">
        <div style={{ display: "flex", alignItems: "center" }}>
            <Select size="large" bordered={false} style={{ flex: "1 1 auto", margin: "20px 0" }} value={account?.nickname} onChange={handleChangeAccount}>
                {accounts?.map(account => {
                    return <Select.Option key={account.nickname} value={account.nickname}>
                        <h2 style={{ color: "#444", paddingLeft: 20 }}><WechatOutlined /> {account.nickname} </h2>
                    </Select.Option>
                })}
            </Select>

            <Button type="link" danger size="small" onClick={handleLogout}>退出登录</Button>
        </div>
        <Menu className="setting-menu" selectedKeys={[activeKey]} items={[
            {
                label: <Link to="/group">群管理</Link>,
                key: 'group',
                icon: <TeamOutlined />
            },
            {
                label: <Link to="/friend">好友管理</Link>,
                key: 'friend',
                icon: <UserOutlined />
            },
            // {
            //     label: <Link to="/message">消息管理</Link>,
            //     key: 'message',
            //     icon: <MessageOutlined />
            // },
            account?.profile?.terminal?.profile_setting_allow && {
                label: <Link to="/setting">设置</Link>,
                key: 'setting',
                icon: <SettingOutlined />
            }
        ]}>

        </Menu>

        <Menu className="session-menu" selectedKeys={[activeKey]} style={{ borderRadius: 10 }} items={
            [
                {
                    label: "会话",
                    key: 'session',
                    icon: <GroupOutlined />,
                    children: roomListMenuItems.concat(contactListMenuItems),
                    type: "group"
                }
            ]}>
        </Menu>
    </div>
}