import { message, Button, Form } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useState, useEffect } from "react"
import { ProfileRequestForm } from "./components/ProfileRequestForm"
import { NavActive, selectAccount, selectJsonRequest, selectUser } from "./components/Global"
import { SaveFilled, SaveOutlined, SaveTwoTone } from "@ant-design/icons"

export function Setting() {
    const [setting, setSetting] = useState(null)

    const jsonRequest = useSelector(selectJsonRequest)
    const currentAccount = useSelector(selectAccount)
    const user = useSelector(selectUser)
    const dispatch = useDispatch();
    const [form] = Form.useForm()

    useEffect(() => {
        dispatch(NavActive("setting"));
        load()

        return () => {

        }
    }, [])

    const load = () => {
        jsonRequest.get("/bot/terminal/setting",{accountType: currentAccount.type, accountId: currentAccount.id, id: user.id})
            .then(resp => resp.json())
            .then(({ data }) => {
                setSetting(data)
            })
    }

    const save = () => {
        form
            .validateFields()
            .then((v) => {
                jsonRequest.post("/bot/terminal/setting", { accountType: currentAccount.type, accountId: currentAccount.id,param: v })
                    .then(resp => resp.json())
                    .then(({ data }) => {
                        message.success("设置成功")
                        // load()
                    })
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }


    return <div className="page setting">
        <h2 style={{ fontWeight: 400, margin: "20px 0 10px 0" }}>设置 <span style={{ color: "#888", fontSize: 16 }}>Setting</span></h2>

        {setting && <ProfileRequestForm form={form} initialValues={setting}/>}
        <Button block type="link" icon={<SaveTwoTone />} onClick={save}>保存</Button>
    </div>
}