import { createSlice } from '@reduxjs/toolkit';
import JsonRequest from '../components/json_request'
import { io } from "socket.io-client"
import configFunc from '../config';

const user = JSON.parse(localStorage.getItem("sign-in-user") || "null")
const currentAccount = JSON.parse(localStorage.getItem("current-account") || "null")

const initialState = {
    // 当前用户
    user: user,
    // 导航栏当前激活的key
    navActiveKey: null,
    // json请求客户端
    jsonRequest: JsonRequest.tokenClient(user, currentAccount),
    // socket 
    socket: io(configFunc.SocketEndpoint(""), { path: `/websocket/terminal/socket.io`, transports: ["websocket"], query: { token: user?.token } }),
    // 当前账户
    account: currentAccount,
    online: false
};

export const counterSlice = createSlice({
    name: 'global',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes

        // 初始化 Socket
        socketInit: (state, action) => {
            return { ...state, socket: action.payload }
        },

        // 设置当前活跃的导航项
        navActive: (state, action) => {
            state.navActiveKey = action.payload
        },

        // 用户登录
        signIn: (state, action) => {
            let defaultAccount = action.payload.accounts[0]
            if (!state.account) {
                localStorage.setItem("current-account", JSON.stringify(defaultAccount))
                state.jsonRequest?.setAccount(defaultAccount)
                state.jsonRequest?.setUser(action.payload)
            }

            return { ...state, user: action.payload, account: state.account || defaultAccount }
        },

        // 用户退出登录
        signOut: (state) => {
            localStorage.setItem("sign-in-user", "null")
            localStorage.setItem("current-account", "null")
            state.jsonRequest?.setAccount({})
            return { ...state, user: null, account: null }
        },

        // 分配账户
        assignAccount: (state, action) => {
            // 记录到浏览器
            localStorage.setItem("current-account", JSON.stringify(action.payload))
            // 设置请求器的公有参数
            state.jsonRequest?.setAccount(action.payload)
            // 同时重新设置jsonrequest
            return { ...state, account: action.payload }
        },

        // 分配在线状态
        assignOnline: (state, action) => {
            return { ...state, online: action.payload }
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(incrementAsync.pending, (state) => {
    //             state.status = 'loading';
    //         })
    //         .addCase(incrementAsync.fulfilled, (state, action) => {
    //             state.status = 'idle';
    //             state.value += action.payload;
    //         });
    // },
});


//解构赋值语法，将 counterSlice.actions 对象中的属性分别赋值给了相应的常量
export const { socketInit, navActive: NavActive, signIn, signOut, assignJsonClient, assignAccount, assignOnline } = counterSlice.actions;

//选择器函数，收 state 参数并从 state.global 对象中获取相应的属性值
export const selectSocket = (state) => state.global.socket;
export const selectUser = (state) => state.global.user;
export const selectNavActiveKey = (state) => state.global.navActiveKey;
export const selectJsonRequest = (state) => state.global.jsonRequest;
export const selectAccount = (state) => state.global.account;
export const selectAccounts = (state) => state.global.user.accounts;
export const selectOnline = (state) => state.global.online;

export default counterSlice.reducer;