import { useEffect, useState } from "react"
import { selectJsonRequest, selectSocket, selectAccount, selectOnline, NavActive } from "../components/Global"
import { useSelector } from "react-redux"
import { message, Divider, Input, Button } from "antd"
import { useDispatch } from "react-redux"
import { MessageList } from "./components/MessageList"
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingOutlined } from "@ant-design/icons"

import "./Chat.css"

let _messages = []

export function Chat({ ownerType, ownerID }) {
    const jsonRequest = useSelector(selectJsonRequest)
    const socket = useSelector(selectSocket)
    const [messages, setMessages] = useState([])
    const [size, setSize] = useState(10)
    const account = useSelector(selectAccount)
    const isOnline = useSelector(selectOnline)
    // 输入的消息
    const [messageSendText, setMesasgeSendText] = useState("")
    const [hasMore, setHasMore] = useState(true)
    const dispatch = useDispatch();

    useEffect(() => {
        _messages = []//将 _messages 数组重置为空数组
        setMessages(_messages)

        socket
            .on("no-contact", () => {
                message.warning('消息发送失败，找不到联系人')
            })
            .on("no-room", () => {
                message.warning('消息发送失败，找不到群')
            })
            .on("message", (message) => {
                // TODO  暴力匹配判断
                if ((ownerType === "bot_room" && message.roomName === ownerID) || (ownerType === "bot_contact" && !message.roomName && (message.receiverName === ownerID || message.talkerName === ownerID))) {
                    _messages = [message].concat(_messages)//将消息添加到 _messages 数组的开头
                    setMessages(_messages)
                }
            })

        dispatch(NavActive(ownerID));
        load(null, [])//加载初始的消息列表

        //清理函数，在组件卸载时取消注册事件监听器
        //调用 socket.off 方法，取消事件监听器，确保在组件卸载时不再接收这些事件
        return () => {
            socket.off("message")
            socket.off("no-contact")
            socket.off("no-room")
        }
    }, [ownerType, ownerID])

    //获取历史消息数据
    const load = (before_id, messages) => {
        setSize(size)
        jsonRequest.get("/bot/terminal/messages/chat", { owner_type: ownerType, owner_id: ownerID, accountId:account.id,accountType:account.type, before_id })
            .then(resp => resp.json())
            .then(({ data }) => {
                _messages = messages.concat(data)
                setMessages(_messages)
                setHasMore(data.length >= 30)//根据新加载的数据长度是否超过30来决定是否还有更多历史消息可以加载
            })
    }

    // 发送消息，并清空输入框中的消息内容
    // socket.emit 是 socket.io 提供的一个方法，用于向服务器发送事件
    const handleMessageSend = () => {
        socket.emit("message-send", { text: messageSendText, ownerType, ownerID })
        setMesasgeSendText("")
    }

    return (
        <>
            <div id="messages" className="messages" style={{
                display: "flex",
                flexDirection: 'column-reverse',
            }}>
                <InfiniteScroll
                    dataLength={messages?.length || 0}
                    next={() => load(messages?.[messages?.length - 1]?.id, messages)}
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                    inverse={true}
                    hasMore={hasMore}
                    loader={<div style={{ textAlign: "center" }}><LoadingOutlined /></div>}
                    scrollableTarget="messages"
                    endMessage={<Divider plain style={{ color: "#aaa" }}>没有更多消息了...</Divider>}
                >
                    <MessageList messages={messages} accountType={account?.type} accountID={account?.id} />
                </InfiniteScroll>
            </div>
            {isOnline && (
                <Input.TextArea
                    placeholder="CTRL + ENTER 发送消息"
                    suffix={<Button onClick={handleMessageSend}>发送</Button>}
                    autoFocus
                    value={messageSendText}
                    onChange={(e) => setMesasgeSendText(e.target.value)}
                    onKeyDown={e => {
                        if (e.key === "Enter" && e.ctrlKey) {
                            handleMessageSend()
                            return false
                        }
                    }}
                />
            )}
        </>
    )
}
