import { Outlet } from "react-router-dom";
import { selectUser } from './components/Global';
import { useNavigate } from "react-router-dom";
// import { message } from "antd"
// import { Socket } from "phoenix"
// UI
import { Nav } from './components/Nav'
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { assignOnline, selectSocket } from "./components/Global"

function Layout(_props) {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const socket = useSelector(selectSocket)

    const user = useSelector(selectUser)
    if (!user) {
        setTimeout(() => navigate("/sign_in", { replace: true }))
    }

    useEffect(() => {
        if (!!user) {
            socket
                .on("login", ({ }) => {
                    dispatch(assignOnline(true))
                })
                .on("logout", ({ }) => {
                    dispatch(assignOnline(false))
                })
        }
    }, [])

    return !!user && <div className="App">
        <main role="main" className="module">
            <div className="module page">
                <div className="side-menu">
                    <Nav />
                </div>
                <div className="content">
                    <Outlet />
                </div>
            </div>
        </main>
    </div>
}

export default Layout;